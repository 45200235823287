import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

import DefaultLayout from '@/layouts/DefaultLayout'

// Views
const Dashboard = () => import('@/views/Dashboard')
const Lending = () => import('@/views/Lending')
const Login = () => import('@/views/Login')
const Signup = () => import('@/views/Signup')
const ForgotPassword = () => import('@/views/ForgotPassword')
const ConfirmEmail = () => import('@/views/ConfirmEmail')
const Account = () => import('@/views/Account')

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: DefaultLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard',
        },
      },

      {
        path: '/kucoin',
        name: 'Kucoin',
        redirect: 'kucoin/lending',
      },
      {
        path: '/kucoin/lending',
        name: 'Lending',
        component: Lending,
        meta: {
          title: 'Lending',
        },
      },

      {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {
          title: 'Account',
        },
      },
    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      title: 'Signup',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot your password',
    },
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
      title: 'Confirm your email',
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => {
      store.dispatch('auth/logout').then(() => {
        router.push('/login')
      })
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/dashboard',
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/login',
    '/signup',
    '/forgot-password',
    '/confirm-email',
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.state.auth.status.loggedIn

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    store.dispatch('auth/restoreUser').then(
      () => {
        next()
      },
      () => {
        store.dispatch('auth/logout').then(() => {
          next('/login')
        })
      },
    )
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = 'Trading - ' + nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = 'Trading - ' + previousNearestWithMeta.meta.title
  } else {
    document.title = 'Trading'
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el),
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

export default router
