<template>
  <div class="bg-light dark:bg-transparent">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>

    <ChangelogModal />
  </div>
</template>

<script>
import AppSidebar from '../components/AppSidebar'
import AppHeader from '../components/AppHeader'
import AppFooter from '../components/AppFooter'
import ChangelogModal from '@/components/ChangelogModal'

export default {
  name: 'DefaultLayout',
  components: {
    ChangelogModal,
    AppSidebar,
    AppHeader,
    AppFooter,
  },
  created() {
    if (!this.$store.state.auth.status.loggedIn) {
      console.log('Trying to restore user')
      this.$store.dispatch('auth/restoreUser').then(
        () => {
          console.log('User restored')
          this.$router.push('/dashboard')
        },
        () => {
          console.log('User not restored')
        },
      )
    }
  },
}
</script>
