// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { UserKey, User, Lend, KucoinAccount, Currency, Order } = initSchema(schema);

export {
  UserKey,
  User,
  Lend,
  KucoinAccount,
  Currency,
  Order
};