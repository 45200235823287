<template>
  <CModal
    backdrop="static"
    :visible="isVisible"
    @close="
      () => {
        $store.state.changelogVisible = false
      }
    "
  >
    <CModalHeader>
      <CModalTitle>Changelog</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CAccordion :active-item-key="1">
        <CAccordionItem :item-key="1">
          <CAccordionHeader>18/02/2022</CAccordionHeader>
          <CAccordionBody>
            <ul>
              <li class="text-success">
                + Added more time intervals for charts
              </li>
              <li class="text-warning">* Fixed lends sync</li>
              <li class="text-warning">* Fixed tables pagination</li>
            </ul>
          </CAccordionBody>
        </CAccordionItem>
        <CAccordionItem :item-key="2">
          <CAccordionHeader>02/02/2022</CAccordionHeader>
          <CAccordionBody>
            <ul>
              <li class="text-success">
                + Added a changelog for future updates
              </li>
              <li class="text-success">+ Added a "Forgot Password" form</li>
              <li class="text-warning">* Performance & Security improvement</li>
              <li class="text-warning">* Changed the scrollbar</li>
              <li class="text-warning">* Fixed account currency</li>
              <li class="text-danger">- Removed some strings not translated</li>
              <li class="text-danger">- Removed broken "Last update"</li>
            </ul>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CModalBody>
  </CModal>
</template>

<script>
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'ChangelogModal',
  setup() {
    const store = inject('store')

    if (!store) return

    const isVisible = computed(() => store.state.changelogVisible)

    return {
      isVisible,
    }
  },
})
</script>

<style scoped></style>
