<template>
  <router-view />
</template>

<script>
import { DataStore } from 'aws-amplify'
import { KucoinAccount, Lend } from './models'
import { defineComponent, watch } from 'vue'

export default defineComponent({
  name: 'App',
  data() {
    return {
      kucoinAccountSubscription: undefined,
      lendSubscription: undefined,
      lendUpdateTimeout: undefined,
    }
  },
  created() {
    this.kucoinAccountSubscription = DataStore.observeQuery(
      KucoinAccount,
    ).subscribe(({ items }) => {
      if (items && items.length > 0) {
        this.$store.dispatch('account/set', items[0])
      }
    })

    this.lendSubscription = DataStore.observeQuery(Lend).subscribe(
      ({ items, isSynced }) => {
        if (items && items.length > 0 && isSynced) {
          if (this.lendUpdateTimeout !== undefined) {
            clearTimeout(this.lendUpdateTimeout)

            this.lendUpdateTimeout = setTimeout(() => {
              this.$store.commit('lends/setLends', items)
            }, 5000)
          } else {
            this.$store.commit('lends/setLends', items)
            this.lendUpdateTimeout = 0
          }
        }
      },
    )

    watch(
      () => this.$store.state.preferences.darkTheme,
      (value) => {
        document.body.classList.toggle('dark-theme', value)
      },
      { immediate: true },
    )
  },
  unmounted() {
    if (this.kucoinAccountSubscription) {
      this.kucoinAccountSubscription.unsubscribe()
    }

    if (this.lendSubscription) {
      this.lendSubscription.unsubscribe()
    }

    clearTimeout(this.lendUpdateTimeout)
  },
})
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
