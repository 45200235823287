import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVuePro from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import Amplify, { DataStore } from 'aws-amplify'
import awsconfig from './aws-exports'

import { Chart } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'

Chart.register(zoomPlugin)

Amplify.configure(awsconfig)

DataStore.configure({
  DataStore: {
    syncPageSize: 100,
  },
  syncPageSize: 100,
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVuePro)
app.component('CIcon', CIcon)
app.provide('icons', icons)

app.mount('#app')
