<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarNav>
      <CRouterLink component="CNavItem" :to="{ name: 'Dashboard' }">
        <CIcon icon="cil-speedometer" custom-class-name="nav-icon" />
        Dashboard
      </CRouterLink>
      <CNavGroup icon="kucoin" name="Kucoin" route="/kucoin">
        <template #togglerContent>
          <CIcon icon="kucoin" custom-class-name="nav-icon" />
          Kucoin
        </template>
        <CRouterLink component="CNavItem" :to="{ name: 'Lending' }">
          <CIcon icon="cil-chart-line" custom-class-name="nav-icon" />
          Lending
        </CRouterLink>
      </CNavGroup>
      <CRouterLink component="CNavItem" :to="{ name: 'Account' }">
        <CIcon icon="cil-user" custom-class-name="nav-icon" />
        Account
      </CRouterLink>
      <CRouterLink component="CNavItem" :to="{ name: 'Logout' }">
        <CIcon icon="cil-account-logout" custom-class-name="nav-icon" />
        Logout
      </CRouterLink>
    </CSidebarNav>
    <div>
      <CSidebarNav>
        <CNavItem>
          <CNavLink
            component="button"
            class="btn btn-link w-100"
            @click="$store.commit('toggleChangelog')"
          >
            <CIcon icon="cil-notes" custom-class-name="nav-icon" />
            Changelog
          </CNavLink>
        </CNavItem>
      </CSidebarNav>
      <CSidebarToggler
        class="d-none d-lg-flex"
        @click="$store.commit('toggleUnfoldable')"
      />
    </div>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import CRouterLink from '@/components/CRouterLink'

export default {
  name: 'AppSidebar',
  components: {
    CRouterLink,
  },
  setup() {
    const store = useStore()

    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
