<template>
  <CFooter :fixed="false" class="mt-4">
    <div>
      <span class="ml-1">
        &copy; {{ new Date().getFullYear() }}
        <CLink href="https://twitter.com/Moutard3" target="_blank">
          Moutarde
        </CLink>
      </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
