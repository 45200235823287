import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { auth } from './auth'
import { account } from './account'
import { lends } from './lends'
import { orders } from './orders'
import { preferences } from './preferences'

const state = {
  sidebarVisible: true,
  sidebarUnfoldable: true,
  changelogVisible: false,
  lastUpdate: null,
}

const mutations = {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  toggleChangelog(state) {
    state.changelogVisible = !state.changelogVisible
  },
  updateSidebarVisible(state, payload) {
    state.sidebarVisible = payload.value
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
}

const persistedState = createPersistedState({
  paths: ['preferences'],
})

export default new createStore({
  state,
  mutations,
  modules: {
    account,
    auth,
    lends,
    orders,
    preferences,
  },
  plugins: [persistedState],
})
