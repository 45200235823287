import { Hub, Auth, DataStore } from 'aws-amplify'
import { User } from '@/models'

class AuthService {
  async login(user) {
    try {
      await Auth.signIn(user.email, user.password)

      const currentLocalUsers = await DataStore.query(User)
      if (
        !currentLocalUsers.length ||
        currentLocalUsers[0].email !== user.email
      ) {
        await DataStore.clear()
      }

      await DataStore.stop()
      await DataStore.start()

      return await new Promise((resolve) => {
        const HubListener = (data) => {
          console.log(data)

          if (
            data.payload.event === 'modelSynced' &&
            data.payload.data.model.name === 'User'
          ) {
            resolve(this.currentAuthenticatedUser())

            Hub.remove('datastore', HubListener)
          }
        }

        Hub.listen('datastore', HubListener)
      })
    } catch (error) {
      console.log('error signing in', error)

      await DataStore.stop()

      return Promise.reject(error)
    }
  }

  async logout() {
    await Auth.signOut()
    await DataStore.stop()
  }

  async register(user) {
    try {
      const { user: registeredUser } = await Auth.signUp({
        username: user.email,
        password: user.password,
        attributes: {
          email: user.email,
        },
      })

      return Promise.resolve(registeredUser)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendCode(email) {
    try {
      const res = await Auth.forgotPassword(email)

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async sendConfirmationCode(email) {
    try {
      const res = await Auth.resendSignUp(email)

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async changePassword(user, code) {
    try {
      const res = await Auth.forgotPasswordSubmit(
        user.email,
        code,
        user.password,
      )

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async confirmSignUp(user, code) {
    try {
      const res = await Auth.confirmSignUp(user.email, code)

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async currentAuthenticatedUser() {
    let cognito
    try {
      cognito = await Auth.currentAuthenticatedUser()
    } catch (error) {
      return Promise.reject(error)
    }

    let db
    try {
      db = await DataStore.query(User)

      if (!db || db.length === 0) {
        return Promise.reject('User not found in database.')
      }

      db = db.at(-1)
    } catch (error) {
      return Promise.reject(error)
    }

    if (!db || !cognito) {
      return Promise.reject('Internal error occured.')
    }

    return Promise.resolve({ cognito, db })
  }
}

export default new AuthService()
