const initialState = () => {
  return {
    items: [],
  }
}

export const orders = {
  namespaced: true,
  state: initialState(),
  actions: {},
  mutations: {
    reset(state) {
      state.items.length = 0
    },
    setOrders(state, payload) {
      console.log('Orders updated.', payload.length)
      state.items = payload
    },
  },
}
