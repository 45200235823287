<template>
  <router-link :to="this.routeTo" custom v-slot="{ href, navigate, isActive }">
    <component
      :is="this.resolvedComponent"
      :active="isActive"
      :href="href"
      @click="navigate"
      ><slot
    /></component>
  </router-link>
</template>

<script>
import { defineComponent, resolveComponent } from 'vue'

export default defineComponent({
  name: 'CRouterLink',
  props: {
    to: { name: String },
    component: {
      type: String,
      default: 'CLink',
    },
  },
  setup(props) {
    return {
      routeTo: props.to,
      resolvedComponent: resolveComponent(props.component),
    }
  },
})
</script>
