const initialState = () => {
  return {
    darkTheme: false,
  }
}

export const preferences = {
  namespaced: true,
  state: initialState(),
  actions: {
    toggleDarkTheme({ commit }) {
      commit('toggleDarkTheme')
    },
  },
  mutations: {
    toggleDarkTheme(state) {
      document.body.classList.toggle('dark-theme', state.darkTheme)

      state.darkTheme = !state.darkTheme
    },
  },
}
