<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex ms-auto">
        <CNavItem>
          <CButton
            @click="$store.dispatch('preferences/toggleDarkTheme')"
            :color="$store.state.preferences.darkTheme ? 'dark' : 'light'"
          >
            <CIcon
              icon="cil-sun"
              size="lg"
              v-if="this.$store.state.preferences.darkTheme"
            />
            <CIcon icon="cil-moon" size="lg" v-else />
          </CButton>
        </CNavItem>
      </CHeaderNav>
    </CContainer>

    <CHeaderDivider />

    <CContainer fluid class="small">
      <CRow class="w-100 justify-content-between align-items-center">
        <CCol class="text-center" lg md="6" sm="12">
          <CRow class="justify-content-center align-items-center">
            <CCol class="px-0" xs="auto">
              <CBadge class="me-2 d-block" color="primary">Total</CBadge>
            </CCol>
            <CCol class="px-0" xs="auto">
              <strong>{{ totalBalanceText }}</strong>
              <strong class="ms-1">({{ totalBalanceEurText }})</strong>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="text-center" lg md="6" sm="12">
          <CRow class="justify-content-center align-items-center">
            <CCol class="px-0" xs="auto">
              <CBadge class="me-2 d-block" color="warning">Lended</CBadge>
            </CCol>
            <CCol class="px-0" xs="auto">
              <strong>{{ lendBalanceText }}</strong>
              <strong class="ms-1">({{ lendBalanceEurText }})</strong>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="text-center" lg md="6" sm="12">
          <CRow class="justify-content-center align-items-center">
            <CCol class="px-0" xs="auto">
              <CBadge class="me-2 d-block" color="secondary">
                Active Orders
              </CBadge>
            </CCol>
            <CCol class="px-0" xs="auto">
              <strong>{{ activeOrdersText }}</strong>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="text-center" lg md="6" sm="12">
          <CRow class="justify-content-center align-items-center">
            <CCol class="px-0" xs="auto">
              <CBadge class="me-2 d-block" color="success">Interests</CBadge>
            </CCol>
            <CCol class="px-0" xs="auto">
              <strong>{{ interestText }}</strong>
              <strong class="ms-1">({{ interestEurText }})</strong>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="text-center" lg md="6" sm="12">
          <CRow class="justify-content-center align-items-center">
            <CCol class="px-0" xs="auto">
              <CBadge class="me-2 d-block" color="info">Available</CBadge>
            </CCol>
            <CCol class="px-0" xs="auto">
              <strong>{{ balanceText }}</strong>
              <strong class="ms-1">({{ balanceEurText }})</strong>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>
  </CHeader>
</template>

<script>
import BigNumber from 'bignumber.js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppHeader',
  computed: {
    balance() {
      return new BigNumber(this.$store.state.account.balance)
    },
    balanceText() {
      return this.balance.toFixed(2) + ' ' + this.$store.state.account.currency
    },

    lendBalance() {
      return this.$store.state.lends.items
        .filter(
          (lend) => lend.settledAt === undefined || lend.settledAt === null,
        )
        .reduce((a, b) => a.plus(b.size).minus(b.repaid), new BigNumber(0))
    },
    lendBalanceText() {
      return (
        this.lendBalance.toFixed(2) + ' ' + this.$store.state.account.currency
      )
    },

    activeOrders() {
      return this.$store.state.orders.items.reduce(
        (a, b) => a.plus(b.size).minus(b.filledSize),
        new BigNumber(0),
      )
    },
    activeOrdersText() {
      return (
        this.activeOrders.toFixed(2) + ' ' + this.$store.state.account.currency
      )
    },

    interest() {
      const interest = this.$store.state.lends.items.reduce((a, b) => {
        if (b.interest === null && b.accruedInterest === null) {
          return a
        }

        return a.plus(b.interest !== null ? b.interest : b.accruedInterest)
      }, new BigNumber(0))

      return interest.minus(interest.times(0.15))
    },
    interestText() {
      return this.interest.toFixed(2) + ' ' + this.$store.state.account.currency
    },

    totalBalance() {
      const unsettledInterest = this.$store.state.lends.items
        .filter(
          (lend) => lend.settledAt === undefined || lend.settledAt === null,
        )
        .reduce(
          (a, b) =>
            b.accruedInterest !== null ? a.plus(b.accruedInterest) : a,
          new BigNumber(0),
        )

      return this.balance
        .plus(this.lendBalance)
        .plus(unsettledInterest.minus(unsettledInterest.times(0.15)))
    },
    totalBalanceText() {
      return (
        this.totalBalance.toFixed(2) + ' ' + this.$store.state.account.currency
      )
    },

    balanceEur() {
      return new BigNumber(this.$store.state.account.balanceEur)
    },
    balanceEurText() {
      return this.balanceEur.toFixed(2) + ' ' + 'EUR'
    },

    lendBalanceEur() {
      return this.$store.state.lends.items
        .filter(
          (lend) => lend.settledAt === undefined || lend.settledAt === null,
        )
        .reduce(
          (a, b) => a.plus(b.sizeEur).minus(b.repaidEur),
          new BigNumber(0),
        )
    },
    lendBalanceEurText() {
      return this.lendBalanceEur.toFixed(2) + ' ' + 'EUR'
    },

    interestEur() {
      const interest = this.$store.state.lends.items.reduce((a, b) => {
        if (b.interestEur === null && b.accruedInterestEur === null) {
          return a
        }

        return a.plus(
          b.interestEur !== null ? b.interestEur : b.accruedInterestEur,
        )
      }, new BigNumber(0))

      return interest.minus(interest.times(0.15))
    },
    interestEurText() {
      return this.interestEur.toFixed(2) + ' ' + 'EUR'
    },

    totalBalanceEur() {
      const unsettledInterest = this.$store.state.lends.items
        .filter(
          (lend) => lend.settledAt === undefined || lend.settledAt === null,
        )
        .reduce(
          (a, b) =>
            b.accruedInterestEur !== null ? a.plus(b.accruedInterestEur) : a,
          new BigNumber(0),
        )

      return this.balanceEur
        .plus(this.lendBalanceEur)
        .plus(unsettledInterest.minus(unsettledInterest.times(0.15)))
    },
    totalBalanceEurText() {
      return this.totalBalanceEur.toFixed(2) + ' ' + 'EUR'
    },

    lastUpdate() {
      return this.$store.state.lastUpdate
        ? new Date(this.$store.state.lastUpdate).toLocaleString()
        : 'N/a'
    },
  },
})
</script>
