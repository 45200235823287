import AuthService from '@/services/auth'

export const auth = {
  namespaced: true,
  state: {
    user: {
      cognito: null,
      db: null,
    },
    status: {
      loggedIn: false,
    },
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (userLogged) => {
          commit('loginSuccess', { ...userLogged })
          return Promise.resolve()
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )
    },
    async logout({ commit }) {
      await AuthService.logout()
      commit('logout')
      commit('account/reset', null, { root: true })
      commit('lends/reset', null, { root: true })
      commit('orders/reset', null, { root: true })
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
    async restoreUser({ commit }) {
      try {
        const user = await AuthService.currentAuthenticatedUser()

        commit('loginSuccess', { ...user })
        return Promise.resolve(user)
      } catch (error) {
        console.log('not found', error)
        return Promise.reject(error)
      }
    },
    async getCode(_, email) {
      try {
        const res = await AuthService.sendCode(email)

        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getConfirmationCode(_, email) {
      try {
        const res = await AuthService.sendConfirmationCode(email)

        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async changePassword(_, { user, code }) {
      try {
        const res = await AuthService.changePassword(user, code)

        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async confirmSignUp(_, { user, code }) {
      try {
        const res = await AuthService.confirmSignUp(user, code)

        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  mutations: {
    loginSuccess(state, { cognito, db }) {
      state.status.loggedIn = true
      state.user.cognito = cognito
      state.user.db = db
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user.cognito = null
      state.user.db = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user.cognito = null
      state.user.db = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
  },
}
