const initialState = () => {
  return {
    available: 0,
    availableEur: 0,
    balance: 0,
    balanceEur: 0,
    currency: 'USDT',
    holds: 0,
    holdsEur: 0,
  }
}

export const account = {
  namespaced: true,
  state: initialState(),
  actions: {
    set({ commit }, payload) {
      commit('set', payload)
    },
  },
  mutations: {
    set(state, payload) {
      Object.keys(payload)
        .filter((key) => key in state)
        .forEach((key) => (state[key] = payload[key]))
    },
    reset(state) {
      Object.assign(state, initialState())
    },
  },
}
