import {
  cilAccountLogout,
  cilChartLine,
  cilLockLocked,
  cilMenu,
  cilMoon,
  cilNotes,
  cilSpeedometer,
  cilSun,
  cilUser,
} from '@coreui/icons'

import { kucoin } from './js/kucoin'

export const iconsSet = Object.assign(
  {},
  {
    kucoin,
  },
  {
    cilAccountLogout,
    cilChartLine,
    cilLockLocked,
    cilMenu,
    cilMoon,
    cilNotes,
    cilSpeedometer,
    cilSun,
    cilUser,
  },
)
